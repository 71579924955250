/*!
 * Project code
 */

import $ from 'jquery';
window.jQuery = $;
window.$ = $;
const scriptURL = 'https://script.google.com/macros/s/AKfycbx04T5cNizfjQ6UAnXFZ2ZbqOrrO0rcHUWmNI-7ndynkwLqTZ8/exec';
const form = document.forms['contact-submit'];

form.addEventListener('submit', e => {
  e.preventDefault();
  fetch(scriptURL, { method: 'POST', body: new FormData(form) })
    .then(() => {
      var messageAlert = 'alert-success';
      var messageText = 'Merci. Nous reviendrons vers vous dans les meilleurs délais.';

      var alertBox = '<div class="alert ' + messageAlert + ' alert-dismissable"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>' + messageText + '</div>';

      if (messageAlert && messageText) {
        $('#form-contact')
          .find('.messages')
          .html(alertBox);
        $('#form-contact')
          .find('.form-row')
          .empty();
      }
    })
    .catch(() => {
      var messageAlert = 'alert-error';
      var messageText = 'Désolé. Un problème est survenu. Veuillez réessayer plus tard.';

      var alertBox = '<div class="alert ' + messageAlert + ' alert-dismissable"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>' + messageText + '</div>';

      if (messageAlert && messageText) {
        $('#form-contact')
          .find('.messages')
          .html(alertBox);
        $('#form-contact')
          .find('.form-row')
          .empty();
      }
    });
});
